<template>
    <div class="d-flex flex-column p-2 w-100 h-100 overflow-y-auto">
        <h6>{{$t('My Layouts')}}</h6>
        <template v-if="myLayouts.length">
            <div class="layouts-list">
                <div v-for="layout in myLayouts" 
                    class="layout-item"
                    :class="{'current': layout.ID === activeLayoutID}">
                    <button class="btn btn-sm btn-link ps-0 pe-1"
                        :class="{'invisible': layout.ID !== activeLayout?.id || !isDirty || isSaving}"
                        @click="() => save()"
                        :disabled="layout.ID !== activeLayout?.id || !isDirty || isSaving"
                        :title="$t('Save layout changes')">
                        <i class="bi bi-check-lg icon-bold"></i>
                    </button>
                    <button class="btn btn-link text-decoration-none text-truncate p-0 text-start"
                        :title="layout.Description"
                        @click="setLayout(layout.ID)">
                        {{layout.Name}}
                    </button>
                    <button class="btn btn-sm btn-link"
                        :class="{'invisible': layout.ID !== activeLayoutID || !isDirty || isSaving}"
                        :disabled="layout.ID !== activeLayoutID || !isDirty || isSaving"
                        @click="() => dataObject.layoutManager?.reapplyLayout(true, true)"
                        :title="$t('Cancel layout changes')">
                        <i class="bi bi-arrow-counterclockwise text-danger"></i>
                    </button>
                    <ODropdown>
                        <template #default="{target, open}">
                            <button :ref="target" @click="open" class="btn btn-sm btn-link layouts-hoverable py-0 ms-auto">
                                <i class="bi bi-three-dots"></i>
                            </button>
                        </template>
                        <template #dropdown="{container, close}">
                            <div :ref="container" class="dropdown-menu show">

                                    <button class="dropdown-item" @click="() => { openRenameModal(layout); close(); }">
                                        {{$t('Rename')}}
                                        <i class="bi bi-pencil-fill ms-2 float-end"></i>
                                    </button>
                                    <button v-if="canSetDefaults" class="dropdown-item" @click="() => { setLayoutAsDefault(layout); close();}">
                                        {{$t('Set As Default')}}
                                        <i class="bi bi-play-circle ms-2 float-end"></i>
                                    </button> 
                                    <button v-if="canShare" class="dropdown-item" @click="() => {$refs.shareModal?.open(layout); close();}">
                                        {{$t('Publish')}}
                                        <i class="bi bi-share-fill ms-2 float-end"></i>
                                    </button> 
                                    <button class="dropdown-item" @click="() => { openPersonShareModal(layout.ID); close();}">
                                        {{$t('Share With Persons')}}
                                        <i class="bi bi-person-fill ms-2 float-end"></i>
                                    </button> 
                            
                                    <ActionDelete class="dropdown-item" 
                                        confirm :row="layout">
                                        {{$t('Delete')}}
                                        <i class="bi bi-trash-fill ms-2 float-end"></i>
                                    </ActionDelete>
                            </div>
                        </template>
                    </ODropdown>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="text-muted">{{$t('No saved layouts')}}</div>
        </template>

        <hr>
        <h6>{{$t('Published Layouts')}}</h6>
        <div class="hstack gap-3 mb-2 mt-1" v-if="hasHidden">
            <div class="form-check form-switch" v-if="hasHidden">
                <input class="form-check-input" type="checkbox" :id="compId+'showHidden'" :checked="showHidden" @click="() => showHidden = !showHidden">
                <label class="form-check-label" :for="compId+'showHidden'">
                    {{$t('Include hidden')}}
                </label>
            </div>
        </div>
        <template v-if="sharedLayouts.length > 0">
            <div class="layouts-list">
                <div v-for="layout in sharedLayouts" 
                    class="layout-item"
                    v-show="!layout.Hidden || showHidden"
                    :class="{'current': layout.ID === activeLayoutID}">
                    <div v-if="canSetDefaults && layout.Default && couldSaveCurrentLayout && !(layout.ID !== activeLayoutID || !isDirty || isSaving)" class="dropdown" style="display: contents;">
                        <button class="btn btn-sm btn-link ps-0 pe-1"
                            :disabled="layout.ID !== activeLayoutID || !isDirty || isSaving"
                            data-bs-toggle="dropdown"
                            :title="$t('Save layout changes')">
                            <i class="bi bi-check-lg icon-bold"></i>
                        </button>
                        <ul class="dropdown-menu">
                            <li><button @click="() => save(true)" class="dropdown-item">{{ $t('Save Changes') }}</button></li>
                            <li><button @click="() => save()" class="dropdown-item">{{ $t('Save As New ') }}</button></li>
                        </ul>
                    </div>
                    <button v-else-if="couldSaveCurrentLayout && !(layout.ID !== activeLayoutID || !isDirty || isSaving)"
                        class="btn btn-sm btn-link ps-0 pe-1"
                        @click="() => save()"
                        :disabled="layout.ID !== activeLayoutID || !isDirty || isSaving"
                        :title="$t('Save layout changes')">
                        <i class="bi bi-check-lg icon-bold"></i>
                    </button>
                    <button class="btn btn-link text-decoration-none text-truncate p-0 text-start"
                        :class="{'text-decoration-line-through': layout.Hidden}"
                        :title="layout.Description"
                        @click="setLayout(layout.ID)">
                        <i v-if="!layoutIsContextual(layout)" v-person-hover="layout.Person_ID" class="bi bi-person"></i>
                        <i v-else-if="layout.Default" class="bi bi-play-circle"></i>
                        <i v-else v-tooltip="layout.OrgUnit" class="bi bi-share"></i>
                        {{layout.Name}} <span v-if="layout.OrgUnitName">[{{ layout.OrgUnitName }}]</span>
                    </button>
                    <button class="btn btn-sm btn-link"
                        v-if="couldSaveCurrentLayout"
                        :class="{'invisible': layout.ID !== activeLayoutID || !isDirty || isSaving}"
                        :disabled="layout.ID !== activeLayoutID || !isDirty || isSaving"
                        @click="() => dataObject.layoutManager.reapplyLayout(true, true)"
                        :title="$t('Cancel layout changes')">
                        <i class="bi bi-arrow-counterclockwise text-danger"></i>
                    </button>
                    <ODropdown v-if="canShare && layoutIsContextual(layout) && canShareMap[layout.ID]">
                        <template #default="{target, open}">
                            <button :ref="target" @click="open" class="btn btn-sm btn-link layouts-hoverable py-0 ms-auto">
                                <i class="bi bi-three-dots"></i>
                            </button>
                        </template>
                        <template #dropdown="{container, close}">
                            <div :ref="container" class="dropdown-menu show">
                                <button v-if="!layout.Default" class="dropdown-item" @click="() => { openRenameModal(layout); close(); }">
                                    {{$t('Rename')}}
                                    <i class="bi bi-pencil-fill ms-2 float-end"></i>
                                </button>
                                <button v-if="!layout.Default && canSetDefaults" class="dropdown-item" @click="() => { setLayoutAsDefault(layout); close();}">
                                    {{$t('Set As Default')}}
                                    <i class="bi bi-play-circle ms-2 float-end"></i>
                                </button> 
                                <ActionDelete class="dropdown-item" v-if="!layout.Default"
                                    :title="$t('Deleting layout will remove it for all users')"
                                    confirm :row="layout">
                                    {{$t('Delete')}}
                                    <i class="bi bi-x-lg ms-2 float-end"></i>
                                </ActionDelete>
                                <ActionDelete class="dropdown-item" v-else-if="canSetDefaults"
                                    :title="$t('Delete default layout')"
                                    confirm :row="layout">
                                    {{$t('Delete')}}
                                    <i class="bi bi-x-lg ms-2 float-end"></i>
                                </ActionDelete>
                                <button class="dropdown-item" @click.passive="() => { setLayoutAsHidden(layout); close(); }"
                                    :title="layout.Hidden ? $t('Unhide layout for yourself') : $t('Hide layout for yourself')">
                                    {{layout.Hidden ? $t('Unhide') : $t('Hide')}}
                                    <i class="bi ms-2 float-end" :class="layout.Hidden ? 'bi-eye' : 'bi-eye-slash'"></i>
                                </button>
                            </div>
                        </template>
                    </ODropdown>

                    <button v-else-if="!layoutIsContextual(layout)" v-tooltip="$t('Unshare with me')" :disabled="isUnsharing" @click.passive="unshare(layout)" class="btn btn-sm btn-link layouts-hoverable py-0">
                        <i class="bi bi-x-lg"></i>
                    </button>
                    <button v-else class="btn btn-sm btn-link layouts-hoverable py-0" 
                        v-tooltip="layout.Hidden ? $t('Unhide layout') : $t('Hide layout')"
                        @click.passive="setLayoutAsHidden(layout)">
                        <i class="bi" :class="layout.Hidden ? 'bi-eye' : 'bi-eye-slash'"></i>
                    </button>

                </div>
            </div>
        </template>
        <template v-else>
            <span class="text-muted">{{$t('No published layouts')}}</span>
        </template>


        <div class="vstack border-top pt-2 mt-2">
            <div class="hstack flex-wrap">
                <button class="btn  btn-outline-primary btn-sm me-2 mb-1" :disabled="isSaving" @click="createNewLayout()"
                    :title="$t('Create a new layout')">
                    {{$t('New Layout')}}
                </button>
                <button class="btn  btn-outline-primary btn-sm me-2 mb-1" v-if="canBeSavedAs" @click="saveAsNew()"
                    :title="$t('Create a copy of the current layout')" :disabled="isSaving">
                    {{$t('Save As')}}
                </button>
                <button v-if="activeLayout && !activeLayout.isDefault" class="btn btn-outline-primary btn-sm me-2 mb-1" :disabled="isSaving" @click="dataObject.layoutManager?.resetLayout()"
                    :title="$t('Reset to default layout')">
                    {{$t('Reset To Default')}}
                </button>
                <button v-if="dataObject?.layoutManager && !dataObject.layoutManager.activeLayoutValid" class="btn btn-primary btn-sm me-2 mb-1"  @click="dataObject.layoutManager?.reapplyLayout()"
                    :title="$t('Reapply the current layout')">
                    {{$t('Apply')}}
                </button>
            </div>
        </div>

        <ODialog v-model:show="showRenameModal" :title="$t('Rename layout')" @hidden="renameModalClosed" backdrop>
            <div class="o365-dialog-body">
                <label>{{$t('Layout name')}}</label>
                <input v-model="dsLayouts.current.Name"
                    class="form-control form-control-sm mb-1">
                <label>{{$t('Layout description')}}</label>
                <OTextArea v-model="dsLayouts.current.Description"
                    class="form-control form-control-sm"
                    style="max-height: 100px;"
                    :rows="2" auto-grow no-resize/>
            </div>
            <div class="o365-dialog-footer">
                <button type="button" class="btn btn-secondary btn-sm" @click="() => showRenameModal = false">{{$t('Cancel')}}</button>
                <button type="button" :disabled="!dsLayouts.current?.Name" @click="renameLayout" class="btn btn-primary btn-sm">{{$t('Rename')}}</button>
            </div>
        </ODialog>
        <LayoutPublishDialog ref="shareModal" :dataObject="dataObject" @shared="dsLayouts.load()"/>
         <LayoutShareWithPersonModal v-if="personShareLayoutId" ref="personShareModal" :layoutId="personShareLayoutId" @hidden="personShareLayoutId = undefined" noButton />
    </div>
</template>

<script setup lang="ts">
import type { alert, confirm } from 'o365-vue-services';
import type { DataObject } from 'o365-dataobject';

import { getDataObjectById } from 'o365-dataobject';
import { ref, computed, watch, nextTick } from 'vue';
import { useLayoutHelpers, getLayoutsDataObject } from './Layouts.helpers.ts';

import { LayoutType } from 'o365-dataobject';
import LayoutPropertiesModal from './components.Layouts.PropertiesModal.vue';
import LayoutShareWithPersonModal from './Layouts.ShareDialog.vue';
import { SharingCapabilitiesChecker, procSetLayoutAsHidden, procUnsetLayoutAsHidden } from 'o365-dataobject';
import { context, userSession, app, configurableRegister } from 'o365-modules';
import { ODropdown, OModal, ODialog, OTextArea } from 'o365-ui-components';
import { OActionConfirm, OActionDelete as ActionDelete } from 'o365-data-components';
import LayoutPublishDialog from './Layouts.PublishDialog.vue';
import {OFieldFilter as FieldFilter} from 'o365-filter-components';
import { useDataObjectEventListener, vTooltip } from 'o365-vue-utils';
import vPersonHover from 'system.libraries.vue.directive.personHover.ts';
import { $t, logger } from 'o365-utils';

export interface IProps {
    dataObject: DataObject
};

const props = defineProps<IProps>();

const showRenameModal = ref(false);
const showHidden = ref(false);
const isUnsharing = ref(false);
const compId = computed(() => {
    return `${props.dataObject.id}_layoutsList`;
});
const personShareLayoutId = ref<number>();
const personShareModal = ref<InstanceType<typeof LayoutShareWithPersonModal>>();

function asyncAlert(...args: Parameters<typeof alert>) {
    return import('o365-vue-services').then((services) => services.alert(...args));
}

function asyncConfirm(...args: Parameters<typeof confirm>) {
    return import('o365-vue-services').then((services) => services.confirm(...args));
}

const dsLayouts = getLayoutsDataObject(props.dataObject) as DataObject;
const { activeLayout, activeLayoutID, isDirty, couldSaveCurrentLayout, isSaving, setLayout, save, saveAsNew, canShare, canSetDefaults, setLayoutAsDefault, canBeSavedAs,
    createNewLayout, canShareMap } = useLayoutHelpers(props.dataObject);

const myLayouts = computed(() => dsLayouts.data.filter(x => x.Person_ID == userSession.personId));
const sharedLayouts = computed(() => dsLayouts.data.filter(x => x.Person_ID != userSession.personId));

const hasHidden = computed(() => sharedLayouts.value.some(layout => layout.Hidden));

function layoutIsContextual(pLayout) { return pLayout.OrgUnit_ID && !pLayout.Default; }

function renameModalClosed() {
    dsLayouts.cancelChanges();
}

async function openPersonShareModal(pLayoutId) {
    personShareLayoutId.value = pLayoutId;
    await nextTick()
    personShareModal.value.show();
}

function openRenameModal(layout) {
    dsLayouts.setCurrentIndex(layout.index);
    showRenameModal.value = true;
}

function renameLayout() {
    dsLayouts.recordSource.save();
    if (activeLayout.value && activeLayout.value.id == dsLayouts.current?.ID) {
        activeLayout.value.name = dsLayouts.current!.Name;
    }
    showRenameModal.value = false;
}

async function setLayoutAsHidden(pLayout: any) {
    if (!pLayout.Hidden) {
        await procSetLayoutAsHidden.execute({
            ID: pLayout.ID
        });
        pLayout.updateOrExtendItem({Hidden: true});
    } else {
        await procUnsetLayoutAsHidden.execute({
            ID: pLayout.ID
        });
        pLayout.updateOrExtendItem({Hidden: false});
    }
}

async function unshare(pLayout) {
    try {
        isUnsharing.value = true;
        await props.dataObject.layoutManager.unshareLayout(pLayout.ID);
        dsSharedLayouts.remove(pLayout.index);
    } finally {
        isUnsharing.value = false;
    }
}


dsLayouts.load();

</script>

<style scoped>
.layout-item {
    width: 100%;
    display: flex;
}

.layout-item.current button:not(.dropdown-item) {
    font-weight: bold;
}
.layout-item.current button:hover:not(.dropdown-item) {
    font-weight: bold;
}

.dirty-indicator {
    display: none;
    font-size: small;
    margin-left: .25rem;
}
.dirty-layout .layout-item.current .dirty-indicator {
    display: inline-block!important;
}

.layouts-list {
    overflow-y: auto;
    min-height: 32px;
}

.layouts-hoverable {
    display: none;
}
.layout-item:hover .layouts-hoverable {
    display: unset;
}
</style>